/* Add your styles here */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F8F8F8;
}

.login-box {
    width: 400px;
    padding: 40px;
    text-align: center;
    background: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.login-box input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-box button {
    width: 100%;
    padding: 10px;
    background-color: #2C2C2E;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}